import React, { PureComponent } from 'react'
import { push } from 'gatsby'
import classnames from 'classnames'
import delay from 'await-delay'

import Layout from '../components/layout'
import logoimg from '../images/logo.svg'

import '../styles/index.scss'

export default class IndexPage extends PureComponent {

  state = {
    logo: false,
    progress: false,
    fullbg: false,
  }

  async componentDidMount () {
    await delay( 100 )
    this.setState({ logo: true })
    await delay( 500 )
    this.setState({ progress: true })
    await delay( 2000 )

    this.setState({ logo: false, fullbg: true })
    await delay( 1000 )

    // navigate to home
    push('/home')
  }

  render() {
    const { logo, progress, fullbg } = this.state

    return (
      <Layout page='index' className={classnames({ logo, progress, fullbg })}>
        <div className='content'>
          <img className='intrologo' src={ logoimg } alt='AARP' />
        </div>
      </Layout>
    )
  }
}