import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import classnames from 'classnames'

// styles
import '../styles/normalize.css'
import '../styles/layout.scss'
import '../styles/layout.scss'
import '../fonts/Avenir/index.css'

const Layout = ({ children, page, className, ...props }) => (
  <div className={classnames(className, 'layout', { [`${page}-page`]: page })} {...props}>
    <Helmet>
      <title>AARP Innovation Labs - Coming soon</title>
    </Helmet>
    {children}
  </div>
)

Layout.propTypes = {
  page: PropTypes.string,
  children: PropTypes.element.isRequired,
}

export default Layout